<style scoped>
.img-box {
  width: 140px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 70px;
  position: absolute;
  top: -70px;
  line-height: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.img-box .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 116px;
  height: 116px;
  border-radius: 50%;
}
/* .container {
  margin-top: 200px;
} */
.card {
  width: 100%;
  height: 580px;
  border-radius: 5px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 0 30px;
}
.card-header .text {
  font-size: 16px;
  line-height: 16px;
  color: #333;
}
.card-header .text::before {
  margin-right: 8px;
  vertical-align: -2px;
}
.card-header .text:last-child {
  color: #fd3c3c;
}
.card-header .text:last-child::before {
  font-size: 26px;
}
.el-card >>> .el-card__header {
  border: none;
}
.myform {
  width: 740px;
  margin: 0 auto;
}
.el-input >>> .el-input__inner {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-radius: 0;
  height: 54px;
}
.el-card >>> .el-card__body {
  margin-top: 50px;
}
.sex-box {
  text-align: left;
  border-bottom: 1px solid #dcdfe6;
}
.birth-box {
  text-align: left;
  width: 100%;
}
.birth-box .el-date-editor.el-input {
  width: 100%;
}
.el-form-item {
  margin: 0;
  height: 54px;
}
.el-form-item >>> .el-form-item__label {
  padding-right: 40px;
  line-height: 54px;
}
.el-form-item >>> .el-form-item__content {
  height: 54px;
  line-height: 54px;
}
.btn {
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #3798ee;
  color: #3798ee;
  margin-top: 65px;
}
</style>
<template>
  <div class="container" id="container">
    <div class="img-box">
      <el-image class="img" :src="form.avatar || url"  :fit="contain"></el-image>
    </div>
    <el-card class="card">
      <div slot="header" class="card-header">
        <el-button
          class="iconfont icon-fanhui- text"
          type="text"
          @click="backHome"
          >返回首页</el-button
        >
        <el-button class="iconfont icon-guanji text" type="text" @click="logOut()"
          >退出登录</el-button
        >
      </div>
      <el-form
        ref="form"
        label-width="100px"
        class="myform"
        label-position="right"
        label-suffix=":"
        :model="form"
      >
        <el-form-item label="昵称">
          <el-input placeholder="请输入昵称" type="text" disabled  v-model="form.nickName" v-if="form.nickName"> </el-input>
          <el-input placeholder="请输入昵称" type="text" disabled  v-model="nickName" v-else> </el-input>

        </el-form-item>
        <el-form-item label="性别">
          <div class="sex-box">
            <el-radio-group style="margin-left: 15px" v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="生日">
          <div class="birth-box">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.birth" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input placeholder="请输入姓名" v-model="form.realName"> </el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <div class="sex-box">
            <el-input placeholder="请输入手机号" disabled type="tel" v-model="nickName" >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="onSubmit"> 保 存 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {getUser,editUser} from '@/api/base'
export default {
  name: "personal",
  data() {
    return {
      form:{
          avatar:null,
          birth:null,
          nickName:null,
          realName:null,
          mobile:null,
          sex:null
      },
      nickName:null,
      list:null,
      url:require('@/assets/images/bgcpng.png')
    };
  },
  created(){
      this.getList()
  },
  methods: {
    //返回首页
    backHome() {
      this.$router.back();
    },
    //退出登录
    logOut(){
        this.$router.replace('/login')
        sessionStorage.clear()
    },
    //获取个人信息
    async getList(){
        let res = await getUser()
        if (res.code != 1) return this.$message.error(res.message)
        this.form = {...res.dataSingle}
        if(!res.dataSingle?.nickName){
            this.nickName = this.form.mobile.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
        }
    },
    //保存按钮
    async onSubmit(){
        console.log(this.form)
        let data = {...this.form}
        let res = await editUser(data)
        if(res.code == 1) return this.$message.success('保存成功')        
    }
  },
};
</script>

